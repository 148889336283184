<h2 matDialogTitle class="mb-05" style="font-weight: 500;">Faça login</h2>

<div class="mt-1 ml-2 mr-2 mb-1 pb-05">
  <form [formGroup]="form" (submit)="submit()" *ngIf="!sent">
    <mat-form-field class="full-width" appearance="outline">
      <mat-label>Seu e-mail</mat-label>
      <input matInput name="email" autocomplete="email" formControlName="email" placeholder="digite seu email" value="">
    </mat-form-field>

    <small class="text-muted text-center" style="display: block;">você receberá um e-mail de verificação da sua conta. Depois disso, você poderá acessar seu saldo e adquirir pacotes de doações</small>

    <button class="mt-2 full-width" mat-raised-button color="accent"  [disabled]="form.invalid || loading">
      <mat-icon>login</mat-icon>
      <span>Entrar com e-mail</span>
    </button>

    <button type="button" mat-stroked-button class="full-width mt-1" (click)="signinGoogle()" [disabled]="loading">
      <app-rb-icon style="zoom: .5;" icon="google"></app-rb-icon>
      <span class="ml-05">Entrar com Google</span>
    </button>
  </form>
  <div *ngIf="sent">
    <p class="text-center">Um e-mail for enviado para <b>{{form.value.email}}</b> com um link de verificação de conta.</p>
    
    <button class="mt-1 full-width" mat-raised-button color="accent" (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
      <span>Fechar</span>
    </button>
  </div>
</div>