<router-outlet></router-outlet>

<!-- Update alert -->
<div class="new-update primary flex align-center justify-center" *ngIf="updateAvailable">
  <div class="new-update-container flex column align-center justify-center">
    <button mat-button class="mb-2 hidden" (click)="updateAvailable = false">Mais tarde</button>
    <app-rb-icon style="width: 60%; color: #50deddb0;" icon="repair" [stretch]="true"></app-rb-icon>
    <h1 class="mt-2 text-center"><b>Atualização disponível</b></h1>
    <h2 class="text-muted text-center mb-0 mt-1" style="font-weight: 300;">Existe uma atualização pronta para ser carregada. Não se preocupe, não vai demorar!</h2>
    <button mat-raised-button color="accent" class="mt-2" (click)="installUpdate()">Carregar agora</button>
    <!-- <button mat-stroked-button class="mt-2" style="border-color: #ffffff8c; color: #fff" (click)="updateAvailable = false">Mais tarde</button> -->
  </div>
</div>