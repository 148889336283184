import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-app-loader',
  templateUrl: './app-loader.component.html',
  styleUrls: ['./app-loader.component.scss']
})
export class AppLoaderComponent {
  title: string;
  message: string;
  spinnerMode: any = 'indeterminate';
  spinnerValue: Observable<number>;

  constructor(public dialogRef: MatDialogRef<AppLoaderComponent>) { }
}
