import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppConfirmComponent } from './app-confirm.component';

export interface IConfirmData {
  title?: string;
  message?: string;
  primaryButton?: string;
  secondaryButton?: string;
}

@Injectable()
export class AppConfirmService {

  constructor(private dialog: MatDialog) { }

  confirm(data: IConfirmData = {}): Observable<boolean> {
    data.title = data.title || 'Confirmação';
    data.message = data.message || 'Tem certeza disso?';
    data.primaryButton = data.primaryButton || 'OK';
    data.secondaryButton = data.secondaryButton || 'Cancelar';
    let dialogRef: MatDialogRef<AppConfirmComponent>;
    dialogRef = this.dialog.open(AppConfirmComponent, {
      width: '380px',
      disableClose: true,
      data
    });
    return dialogRef.afterClosed();
  }
}
