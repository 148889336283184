import { Component, Inject, ViewChild, Input, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm',
  template: `
    <h2 matDialogTitle class="flex space-between align-center">
      <span>Nova imagem</span>
      <mat-progress-spinner *ngIf="loading" class="ml-1" diameter="20" color="accent"></mat-progress-spinner>
      <span class="flex-grow"></span>
      <button mat-icon-button (focus)="focus()" (click)="selectImage()">
        <mat-icon color='primary'>image_search</mat-icon>
      </button>
    </h2>

    <div mat-dialog-content >
      <input #file style="display: none;" type="file" accept="image/*" (change)="fileChangeEvent($event)"/>
      <image-cropper
      (drop)="drop($event)"
          style="width: 97%;"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="data.square"
          [aspectRatio]="1 / 1"
          format="png"
          (imageCropped)="imageCropped($event)">
      </image-cropper>
      <small class="mt-05"><i>Aperte CTRL+V com um link ou imagem.</i></small>
    </div>

    <div mat-dialog-actions class="pb-1 pl-1 pr-1">
      <button type="button" mat-raised-button (click)="dialogRef.close(false)">Cancelar</button>
      &nbsp;
      <span class="flex-grow"></span>
      <button type="button" mat-raised-button color="primary" (click)="dialogRef.close(croppedImage)" [disabled]="!croppedImage">Salvar</button>
    </div>
  `,
  styles: [`
    h2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 1rem;
    }

    h2 span {
      display: block;
    }
  `]
})
export class AppUploadImageComponent implements AfterViewInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';

  @ViewChild('file', { static: true })
  file: ElementRef;

  first = true;
  loading = false;

  @HostListener('window:paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const items = event.clipboardData?.items;

    this.loading = true;

    if (items && items[0]) {
      const item = items[0];

      if (item.kind === 'file' && item.type.startsWith('image/')) {
        const blob = item.getAsFile();
        if (blob) {
          this.imageChangedEvent = { target: { files: [blob] } };
          this.loading = false;
        }
      }

      if (item.kind === 'string') {
        item.getAsString((text) => {
          console.log(text);
          if (text.startsWith('https') || text.startsWith('http')) {
            if (['png', 'jpg', 'jpeg', 'gif', 'wbp', 'bmap'].some((ext) => text.includes(ext))) {
              fetch(text)
              .then((response) => response.blob())
              .then((blob) => {
                this.imageChangedEvent = { target: { files: [blob] } };
                this.loading = false;
              })
              .catch((error) => {
                console.error('Error fetching image:', error);
                this.loading = false;
              });
            } else {
              this.loading = false;
            }
          }
        });
      }
    }
  }

  constructor(
    public dialogRef: MatDialogRef<AppUploadImageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngAfterViewInit() {
    // this.selectImage();
  }

  drop(event: any) {
    event.preventDefault();
    const file = event.dataTransfer.items[0].getAsFile();
    this.imageChangedEvent = { target: { files: [file] } };
  }

  selectImage() {
    this.file.nativeElement.click();
  }

  fileChangeEvent(event: any) {
    this.imageChangedEvent = event;
  }

  focus() {
    if (!this.first) {
      // setTimeout(() => {
      //   if (!this.imageChangedEvent) {
      //     this.dialogRef.close(false);
      //   }
      // }, 500);
    }

    this.first = false;
  }

  imageCropped(image: ImageCroppedEvent) {
    const reader = new FileReader();
    reader.readAsDataURL(image.blob!);
    reader.onloadend = () => {
      this.croppedImage = reader.result;
    };
    
  }
}
