import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  template: `<h2 matDialogTitle class="mb-05" style="font-weight: 500;">{{ data.title }}</h2>
    <div mat-dialog-content class="pb-0" [innerHtml]="data.message"></div>
    <div mat-dialog-actions class="mt-1">
    <button
    class="ml-1 mr-1 mb-1 flex-grow"
    type="button"
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(true)">OK</button>
    &nbsp;
    <span fxFlex></span>
    </div>`
})
export class AppAlertComponent {
  constructor(
    public dialogRef: MatDialogRef<AppAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
