import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import tinycolor from 'tinycolor2';
import { Observable, shareReplay } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

export enum TemplateColorsEnum {
  Accent = 'accent',
  Amber = 'amber',
  Barn = 'barn',
  Black = 'black',
  Blue = 'blue',
  BlueGrey = 'blue-grey',
  Brown = 'brown',
  Cyan = 'cyan',
  DarkBlue = 'dark-blue',
  DeepOrange = 'deep-orange',
  DeepPurple = 'deep-purple',
  Green = 'green',
  Grey = 'grey',
  Indigo = 'indigo',
  LightBlue = 'light-blue',
  LightGreen = 'light-green',
  LightGrey = 'light-grey',
  LightPurple = 'light-purple',
  Lime = 'lime',
  Orange = 'orange',
  Pink = 'pink',
  Primary = 'primary',
  Purple = 'purple',
  Red = 'warn',
  Slate = 'slate',
  Teal = 'teal',
  White = 'white',
  Yellow = 'yellow'
}

export enum TemplateTextColorsEnum {
  Accent = 'text-accent',
  Amber = 'text-amber',
  Barn = 'text-barn',
  Black = 'text-black',
  Blue = 'text-blue',
  BlueGrey = 'text-blue-grey',
  Brown = 'text-brown',
  Cyan = 'text-cyan',
  DarkBlue = 'text-dark-blue',
  DeepOrange = 'text-deep-orange',
  DeepPurple = 'text-deep-purple',
  Green = 'text-green',
  Grey = 'text-grey',
  Indigo = 'text-indigo',
  LightBlue = 'text-light-blue',
  LightGreen = 'text-light-green',
  LightPurple = 'text-light-purple',
  Lime = 'text-lime',
  Orange = 'text-orange',
  Pink = 'text-pink',
  Primary = 'text-primary',
  Purple = 'text-purple',
  Red = 'text-warn',
  Slate = 'text-slate',
  Teal = 'text-teal',
  White = 'text-white',
  Yellow = 'text-yellow'
}

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private renderer: Renderer2;

  mode$ = new Observable<'dark' | 'light'>((sub) => {
    if (this.document.body.classList.contains('dark-theme')) {
      sub.next('dark');
    } else {
      sub.next('light');
    }

    new MutationObserver((mutations: MutationRecord[]) => {
      if (this.document.body.classList.contains('dark-theme')) {
        sub.next('dark');
      } else {
        sub.next('light');
      }
    }).observe(this.document.body, { attributes: true });
  }).pipe(shareReplay({
    bufferSize: 1,
    refCount: true
  }));

  constructor(@Inject(DOCUMENT) private document: Document,
              rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    const mode = localStorage.getItem('mode');

    if (!mode) {
      if (window.matchMedia) {
        const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
  
        if (matchMedia.matches) {
          this.setMode('dark');
        } else {
          this.setMode('light');
        }
      }
    } else {
      this.setMode(localStorage.getItem('mode') as any || 'light');
    }
  }

  setMode(mode: 'dark' | 'light') {
    document.body.classList.remove('dark-theme');
    document.body.classList.remove('light-theme');
    document.body.classList.add(mode + '-theme');
    localStorage.setItem('mode', mode);
  }

  setTheme(primary: string, accent: string) {
    this.setPrimaryColor(primary);
    this.setAccentColor(accent);
    this.resetBrowserTheme();
  }

  setBrowserTheme(color: string) {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    metaThemeColor?.setAttribute('content', color);
  }

  resetBrowserTheme() {
    this.setBrowserTheme(document.body.style.getPropertyValue('--theme-primary-700'));
  }

  setPrimaryColor(colorHex: string) {
    const primaryColorPalette = this._computeColors(colorHex);

    for (const color of primaryColorPalette) {
      const key1 = `--theme-primary-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-primary-contrast-${color.name}`;
      const value2 = color.darkContrast ? 'rgba(black, 0.87)' : 'white';
      document.body.style.setProperty(key1, value1);
      document.body.style.setProperty(key2, value2);
    }
  }

  setAccentColor(colorHex: string) {
    const secondaryColorPalette = this._computeColors(colorHex);

    for (const color of secondaryColorPalette) {
      const key1 = `--theme-accent-${color.name}`;
      const value1 = color.hex;
      const key2 = `--theme-accent-contrast-${color.name}`;
      const value2 = color.darkContrast ? 'rgba(0, 0, 0, 0.87)' : 'white';
      document.body.style.setProperty(key1, value1);
      document.body.style.setProperty(key2, value2);
    }
  }


  private _computeColors(hex: string): Color[] {
    return [
      this._getColorObject(tinycolor(hex).lighten(72).desaturate(20), '50'),
      this._getColorObject(tinycolor(hex).lighten(57).desaturate(20), '100'),
      this._getColorObject(tinycolor(hex).lighten(26), '200'),
      this._getColorObject(tinycolor(hex).lighten(12), '300'),
      this._getColorObject(tinycolor(hex).lighten(6), '400'),
      this._getColorObject(tinycolor(hex), '500'),
      this._getColorObject(tinycolor(hex).darken(6), '600'),
      this._getColorObject(tinycolor(hex).darken(12), '700'),
      this._getColorObject(tinycolor(hex).darken(18), '800'),
      this._getColorObject(tinycolor(hex).darken(24), '900'),
      this._getColorObject(tinycolor(hex).lighten(50).saturate(30), 'A100'),
      this._getColorObject(tinycolor(hex).lighten(30).saturate(30), 'A200'),
      this._getColorObject(tinycolor(hex).lighten(10).saturate(15), 'A400'),
      this._getColorObject(tinycolor(hex).lighten(5).saturate(5), 'A700')
    ];
  }

  private _getColorObject(value: tinycolor.Instance, name: string): Color {
    const c = tinycolor(value);
    return {
      name,
      hex: c.toHexString(),
      darkContrast: c.isLight()
    };
  }
}
