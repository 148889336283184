import { Injectable } from '@angular/core';
import { Auth, sendPasswordResetEmail, sendSignInLinkToEmail, signInWithCustomToken, signInWithEmailAndPassword, signInWithEmailLink, signOut as signOutFire, updatePassword, updateProfile, User, user, UserCredential, GoogleAuthProvider } from '@angular/fire/auth';
import { signInWithPopup } from 'firebase/auth';
import { IFUser } from 'app/services/user/user.service';
import { LocalStorageService } from 'ngx-webstorage';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CacheService } from './../cache.service';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _currentUser: Observable<User | null>;

  constructor(private storage: LocalStorageService,
              private fireAuth: Auth,
              private cacheService: CacheService) {
    this._currentUser = user(fireAuth);
  }

  get currentUser(): Observable<User | null> {
    return this._currentUser;
  }

  get auth(): Auth {
    return this.fireAuth;
  }

  signInWithEmailAndPassword(email: string, password: string): Observable<UserCredential> {
    return from(signInWithEmailAndPassword(this.auth, email, password));
  }

  sendSignInLinkToEmail(email: string) {
    const url = new URL(window.location.href);

    const newUrl = new URL(window.location.origin + '/logon/signin');
    newUrl.searchParams.set('email', email);
    newUrl.searchParams.set('redirect', url.pathname);

    return from(sendSignInLinkToEmail(this.auth, email, {
      url: newUrl.toString(),
      handleCodeInApp: true
    }));
  }

  signInWithEmailLink(email: string): Observable<UserCredential> {
    return from(signInWithEmailLink(this.auth, email));
  }

  signInWithToken(token: string): Observable<UserCredential> {
    return from(signInWithCustomToken(this.auth, token));
  }

  signInWithGoogle(): Observable<UserCredential> {
    return from(signInWithPopup(this.auth, new GoogleAuthProvider()));
  }
  
  updateUser(newProfile: IFUser) {
    return this.currentUser.pipe(
      switchMap((user) => from(updateProfile(user!, newProfile)).pipe(switchMap(() => this.currentUser)))
    );
  }

  changePassword(email: string, currentPassword: string, newPassword: string): Observable<void> {
    return this.signInWithEmailAndPassword(email, currentPassword).pipe(
      switchMap(() => this.currentUser),
      switchMap((user) => {
        return new Observable<void>((observer) => {
          updatePassword(user!, newPassword)
            .then(() => observer.next())
            .catch((error) => observer.error(error))
            .finally(() => observer.complete());
        });
      })
    );
  }

  sendPasswordResetEmail(email: string) {
    return from(sendPasswordResetEmail(this.auth, email));
  }

  signOut() {
    signOutFire(this.auth);
    this.storage.clear();
    this.cacheService.clearAll();
  }
}
