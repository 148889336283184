import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';

export enum IconsEnum {
  Offline = 'offline',
  Repair = 'repair',
  Google = 'google',
  WhatsApp = 'whatsapp',
  Telegram = 'telegram',
}

const assetsUrl = 'assets/icons';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {}

  registryIcons(...icons: IconsEnum[]) {
    icons.forEach((icon) => {
      if (this.hasIcon(icon)) {
        this.matIconRegistry.getNamedSvgIcon(icon).pipe(take(1)).subscribe(
          () => true,
          () => this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`${assetsUrl}/${icon}.svg`)));
      } else {
        throw new Error(icon + ' icon not found in icons enum.');
      }
    });
  }

  registryAllIcons() {
    Object.keys(IconsEnum).forEach((icon) => {
      this.registryIcons((IconsEnum as any)[icon]);
    });
  }

  hasIcon(icon: string) {
    const find = Object.keys(IconsEnum).find((i) => (IconsEnum as any)[i] === icon);
    return find ? true : false;
  }
}
