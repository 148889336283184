import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppGroupsComponent } from './app-groups.component';

@Injectable()
export class AppGroupsService {

  constructor(private dialog: MatDialog) { }

  alert(): Observable<boolean> {
    const dialogRef = this.dialog.open(AppGroupsComponent, {
      width: '380px',
      disableClose: true
    });

    return dialogRef.afterClosed();
  }
}
