import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { Observable, from, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class HttpsRequestInterceptor implements HttpInterceptor {
  constructor(private afAuth: Auth) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.responseType !== 'json' || (!req.url.includes('rbstore.net') && !req.url.includes('10.68.99.19'))) {
      return next.handle(req);
    }

    return authState(this.afAuth as any).pipe(
      take(1),
      switchMap((user) => user ? from(user.getIdToken()) : of(null)),
      switchMap((token) => {
        const authPayload: Record<string, string> = token ? { 'Content-Type': 'application/json', Authorization: token } : { 'Content-Type': 'application/json' };

        const dupReq = req.clone({
          setHeaders: authPayload
        });

        return next.handle(dupReq);
      }),

    );
  }
}
