import { AfterViewInit, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ThemeService } from 'app/shared/services/theme.service';
import { LocalStorage } from 'ngx-webstorage';
import { filter } from 'rxjs/operators';
import { RoutePartsService } from './shared/services/route-parts.service';
import smartlookClient from 'smartlook-client';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  private _appTitle = 'RB Tipa';
  private _pageTitle = this._appTitle;

  updateAvailable = false;

  @LocalStorage()
  email: string;

  @LocalStorage()
  loggedIn: boolean;

  constructor(private themeService: ThemeService,
              private activeRoute: ActivatedRoute,
              private routePartsService: RoutePartsService,
              private title: Title,
              // private swUpdate: SwUpdate,
              private router: Router) {
  }

  ngAfterViewInit() {
    this.changePageTitle();

    if (environment.production) {
      smartlookClient.init(environment.smartLookKey);
    }

    // let interval: NodeJS.Timeout;

    // this.swUpdate.versionUpdates.subscribe((event) => {
    //   if (event.type === 'VERSION_READY') {
    //     this.updateAvailable = true;

    //     clearInterval(interval);

    //     interval = setInterval(() => {
    //       this.updateAvailable = true;
    //     }, 60000);
    //   }
    // });
  }

  installUpdate() {
    window.location.reload();
  }

  compareVersions(a: string, b: string) {
    const segmentsA = a.split('.').map(Number);
    const segmentsB = b.split('.').map(Number);

    for (let i = 0; i < Math.max(segmentsA.length, segmentsB.length); i++) {
      const segmentA = segmentsA[i] || 0;
      const segmentB = segmentsB[i] || 0;

      if (segmentA >= segmentB) {
        return 1;
      } else if (segmentA < segmentB) {
        return -1;
      }
    }

    return 0;
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this._appTitle);
      }
      // Extract title from parts;
      this._pageTitle = routeParts.reverse().map((part) => part.title ).reduce((partA, partI) => `${partA} > ${partI}`);
      this._pageTitle += ` | ${this._appTitle}`;
      this.title.setTitle(this._pageTitle);
    });
  }
}
