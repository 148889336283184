import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppAlertService } from './components/app-alert/app-alert.service';
import { AppConfirmService } from './components/app-confirm/app-confirm.service';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedAngularModule } from './shared-angular.module';
import { AppUploadImageModule } from './components/app-upload-image/app-upload-image.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedAngularModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    AppUploadImageModule
  ],
  exports: [
    SharedAngularModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    AppUploadImageModule
  ],
  providers: [
    AppAlertService,
    AppConfirmService
  ]
})
export class SharedModule { }
