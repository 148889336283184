import { Component, Input, OnInit } from '@angular/core';
import { IconService } from 'app/shared/services/icon.service';
import { IconsEnum } from '../../services/icon.service';
import { TemplateColorsEnum, TemplateTextColorsEnum } from '../../services/theme.service';

@Component({
  selector: 'app-rb-icon',
  templateUrl: './rb-icon.component.html',
  styleUrls: ['./rb-icon.component.scss']
})
export class RBIconComponent implements OnInit {

  svgIcon!: string;
  idIcon = '';

  @Input()
  set icon(icon: IconsEnum | string) {
    if (this.iconService.hasIcon(icon)) {
      this.iconService.registryIcons(icon as IconsEnum);
      this.svgIcon = icon;
    } else {
      this.idIcon = icon;
    }
  }

  @Input()
  color!: TemplateColorsEnum | TemplateTextColorsEnum;

  @Input()
  fontIcon!: string;

  @Input()
  fontSet!: string;

  @Input()
  inline!: boolean;

  @Input()
  stretch = false;

  constructor(private iconService: IconService) { }

  ngOnInit() { }

}
