import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';

const components = [
  FilterPipe
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule
  ],
  providers: [
  ],
  exports: components
})
export class SharedPipesModule { }
