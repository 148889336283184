import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AppConfirmService } from 'app/shared/components/app-confirm/app-confirm.service';
import { AppLoaderService } from 'app/shared/components/app-loader/app-loader.service';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { AppAlertComponent } from './app-alert/app-alert.component';
import { AppConfirmComponent } from './app-confirm/app-confirm.component';
import { AppGroupsComponent } from './app-groups/app-groups.component';
import { AppGroupsService } from './app-groups/app-groups.service';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { AppLoginComponent } from './app-login/app-login.component';
import { AppLoginService } from './app-login/app-login.service';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { RBIconComponent } from './rb-icon/rb-icon.component';

const components = [
  AuthLayoutComponent,
  AppAlertComponent,
  AppLoginComponent,
  AdminLayoutComponent,
  AppConfirmComponent,
  AppGroupsComponent,
  AppLoaderComponent,
  RBIconComponent
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSidenavModule,
    MatDividerModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    SharedDirectivesModule
  ],
  providers: [
    AppLoaderService,
    AppConfirmService,
    AppGroupsService,
    AppLoginService
  ],
  exports: components
})
export class SharedComponentsModule { }
