import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-groups',
  templateUrl: './app-groups.component.html',
  styleUrls: ['./app-groups.component.scss']
})
export class AppGroupsComponent {
  groups: { whatsapp: string, telegram: string } | undefined;

  constructor(
    public dialogRef: MatDialogRef<AppGroupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  back() {
    if (this.groups) {
      this.groups = undefined;
    } else {
      this.dialogRef.close();
    }
  }

  showGeneral() {
    this.groups = {
      whatsapp: 'https://rbstore.net/groups/whatsapp/general',
      telegram: 'https://t.me/rbstoregeral'
    }
  }

  showHardware() {
    this.groups = {
      whatsapp: 'https://rbstore.net/groups/whatsapp/hardware',
      telegram: 'https://t.me/rbstorenet'
    }
  }
}
