import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppLoaderComponent } from './app-loader.component';
import { filter } from 'rxjs/operators';

@Injectable()
export class AppLoaderService {
  dialogRef: MatDialogRef<AppLoaderComponent>;
  alreadyOpen = false;

  constructor(private dialog: MatDialog) { }

  open(title: string = 'Aguarde', progressPercent?: Observable<number | undefined>): Observable<boolean> {
    if (!this.alreadyOpen) {
      this.alreadyOpen = true;

      this.dialogRef = this.dialog.open(AppLoaderComponent, { disableClose: true, backdropClass: 'light-backdrop'});
      this.dialogRef.updateSize('200px');
      this.dialogRef.componentInstance.title = title;
      if (progressPercent) {
        this.dialogRef.componentInstance.spinnerMode = 'determinate';
        this.dialogRef.componentInstance.spinnerValue = progressPercent.pipe(
          filter((x) => !!x)
        ) as Observable<number>;
      }
    }

    return this.dialogRef.afterClosed();
  }

  close() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.alreadyOpen = false;
    }
  }
}
