import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocalStorage } from 'ngx-webstorage';
import { AuthService } from 'app/shared/services/auth/auth.service';
import { take } from 'rxjs/operators';
import { AppAlertService } from 'app/shared/components/app-alert/app-alert.service';

@Component({
  selector: 'app-login',
  templateUrl: 'app-login.component.html',
  styleUrls: ['app-login.component.scss']
})
export class AppLoginComponent implements OnInit {

  form: FormGroup;

  @LocalStorage()
  email: string;

  sent = false;

  loading = false;

  constructor(
    public dialogRef: MatDialogRef<AppLoginComponent>,
    public alert: AppAlertService,
    public fb: FormBuilder,
    public auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.sent = false;

    this.form = this.fb.group({
      email: [this.email || '', [Validators.required, Validators.email]],
    });
  }

  submit() {
    this.loading = true;
    const email = this.form.value.email;

    this.auth.sendSignInLinkToEmail(email).subscribe(() => {
      this.sent = true;
      this.loading = false;
      this.email = email;
    }, () => {
      this.loading = false;
    });
  }

  signinGoogle() {
    this.loading = true;

    this.auth.signInWithGoogle().pipe(
      take(1)
    ).subscribe(() => {
      this.loading = false;
      this.dialogRef.close();
    },
    (error) => {
      console.error(error);
      this.loading = false;
      this.auth.signOut();
      this.alert.alert({ message: 'Erro ao realizar login com o Google', title: 'Ops.. Algo deu errado' });
    });
  }
}
