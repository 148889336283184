import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AppUploadImageComponent } from './app-upload-image.component';
import { AppUploadImageService } from './app-upload-image.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    ImageCropperModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  exports: [ AppUploadImageComponent ],
  declarations: [ AppUploadImageComponent ],
  providers: [ AppUploadImageService ]
})
export class AppUploadImageModule { }
