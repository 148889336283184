import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppLoginComponent } from './app-login.component';

@Injectable()
export class AppLoginService {

  constructor(private dialog: MatDialog) { }

  login(): Observable<boolean> {
    let dialogRef: MatDialogRef<AppLoginComponent>;

    dialogRef = this.dialog.open(AppLoginComponent, {
      width: '380px'
    });

    return dialogRef.afterClosed();
  }
}
