import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmData } from './app-confirm.service';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="mb-05">{{ data.title }}</h1>
    <div mat-dialog-content [innerHtml]="data.message"></div>
    <div mat-dialog-actions class="ml-1 mb-1">
    <button
    type="button"
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(true)">{{ data.primaryButton }}</button>
    &nbsp;
    <span class="flex-grow"></span>
    <button
    type="button"
    color="accent"
    class="ml-05 mr-1"
    mat-raised-button
    (click)="dialogRef.close(false)">{{data.secondaryButton}}</button>
    </div>`,
})
export class AppConfirmComponent {
  constructor(public dialogRef: MatDialogRef<AppConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) public data: IConfirmData) { }
}
