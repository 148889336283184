<div class="text-center">
  <h4 class="m-0 pb-1 pt-1">
    {{ title }}
    <ng-container *ngIf="spinnerValue">
      {{spinnerValue | async | number:'1.2-2'}}%
    </ng-container>
  </h4>
  <div mat-dialog-content>
    <mat-progress-spinner [mode]="spinnerMode" [value]="spinnerValue | async" [style.margin]="'auto'"></mat-progress-spinner>
  </div>
</div>
