import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPlaceholder]'
})
export class PlaceholderDirective implements OnInit, OnDestroy {
  private changes!: MutationObserver;
  private visible!: boolean;
  private text!: string;

  @Input('appPlaceholder')
  set size(size: number) {
    if (!this.visible) {
      this.text = this.renderer.createText('⠀'.repeat(size || 1));
    }
  }

  constructor(private element: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => this._checkMutation(mutation));
    });

    this.changes.observe(this.element.nativeElement, {
      attributeOldValue: true,
      subtree: true,
      characterData: true,
      characterDataOldValue: true,
    });

    this._addPlaceholder();
  }

  ngOnDestroy() {
    this.changes.disconnect();
  }

  private _checkMutation(mutation: MutationRecord) {
    if (mutation.type === 'characterData') {
      if (mutation.target.nodeValue?.match(/(^ *$)/)) {
        this._addPlaceholder();
      } else {
        this._removePlaceholder();
      }
    }
  }

  private _addPlaceholder() {
    if (!this.visible) {
      this.renderer.addClass(this.element.nativeElement, 'text-placeholder');
      this.renderer.appendChild(this.element.nativeElement, this.text);
      this.visible = true;
    }
  }

  private _removePlaceholder() {
    if (this.visible) {
      this.renderer.removeClass(this.element.nativeElement, 'text-placeholder');
      this.renderer.removeChild(this.element.nativeElement, this.text);
      this.visible = false;
    }
  }
}
