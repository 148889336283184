import { Injectable } from '@angular/core';
import { ref, Storage, uploadBytesResumable } from '@angular/fire/storage';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppUploadImageComponent } from './app-upload-image.component';
import { environment } from 'environments/environment';

@Injectable()
export class AppUploadImageService {

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;

  constructor(private dialog: MatDialog,
              private storage: Storage) { }

  openDialog(square: boolean = false): Observable<string> {
    let dialogRef: MatDialogRef<AppUploadImageComponent>;
    dialogRef = this.dialog.open(AppUploadImageComponent, {
      width: '380px',
      disableClose: true,
      data: { square }
    });
    return dialogRef.afterClosed();
  }

  upload(fileString: string, folder: string = 'uploads') {
    const file = new File([this._base64toBlob(fileString)], new Date().getTime().toString(), { type: 'image/png'});

    const filePath = `${folder}/${file.name}`;

    const storageRef = ref(this.storage, filePath);
    const task = uploadBytesResumable(storageRef, file, {
      cacheControl: 'public, max-age=2629000'
    });

    return { url: `https://${environment.firebase.storageBucket}/${filePath}`,  task };
  }

  private _base64toBlob(stringBase64: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    const byteString = atob(stringBase64.split(',')[1]);

    // separate out the mime component
    const mimeString = stringBase64.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }
}
