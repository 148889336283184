export const environment = {
  production: true,
  version: 'VERSION',
  name: 'prod',
  api: 'https://api.rbstore.net',
  smartLookKey: 'e67d182ffba14f8833aab717f6b932079b30c62e',
  DEFAULT_TIMEZONE: 'America/Sao_Paulo',
  firebase: {
    apiKey: "AIzaSyAQVBsOWrfpnttTlhRY6OT0sBMssqTfHxo",
    authDomain: "rb-store-s.firebaseapp.com",
    projectId: "rb-store-s",
    storageBucket: "static.rbstore.net",
    messagingSenderId: "469748992602",
    databaseURL: 'https://rb-store-s.firebaseio.com',
    appId: "1:469748992602:web:dc7009cdb7a10e63f96663",
    measurementId: "G-KXB9TBMF3B"
  }
};
