import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, startWith, tap } from 'rxjs/operators';

interface CacheContent {
  expiry: number;
  value: any;
}

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * CacheService
 */
@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: Map<string, CacheContent> = new Map<string, CacheContent>();
  private inFlightObservables: Map<string, Subject<any>> = new Map<string, Subject<any>>();
  readonly DEFAULT_MAX_AGE: number = 300000;

  /**
   * Gets the value from cache if the key is provided.
   * If no value exists in cache, then check if the same call exists
   * in flight, if so return the subject. If not create a new
   * Subject inFlightObservable and return the source observable.
   */
  get<T>(key: string, fallback?: Observable<T>, maxAge?: number, fallbackValue?: T): Observable<T> | Subject<T> {

    if (this.hasValidCachedValue(key)) {
      return of(this.cache.get(key)!.value);
    }

    if (maxAge === undefined || maxAge === null) {
      maxAge = this.DEFAULT_MAX_AGE;
    }

    if (this.inFlightObservables.has(key)) {
      return this.inFlightObservables.get(key)!;
    } else if (fallback && fallback instanceof Observable) {
      return fallback.pipe(
          tap((value) => {
            this.inFlightObservables.set(key, new Subject<T>());
            this.set(key, value, maxAge);
          }),
          catchError(error => {
            if (fallbackValue) {
              this.set(key, fallbackValue, 0);
              return of(fallbackValue);
            }
            this.notifyInFlightObserversError(key, error);
            throw error;
          })
        );
    } else {
      throw new Error('Requested key is not available in Cache');
    }
  }

  /**
   * Gets the value from cache if the key is provided.
   * If no value exists in cache, then check if the same call exists
   * in flight, if so return the subject. If not create a new
   * Subject inFlightObservable and return the source observable.
   */
  getAndRefresh<T>(key: string, fallback: Observable<T>, maxAge?: number, fallbackValue?: T): Observable<T> | Subject<T> {
    if (maxAge === undefined || maxAge === null) {
      maxAge = this.DEFAULT_MAX_AGE;
    }

    if (this.hasValidCachedValue(key)) {
      return fallback.pipe(
        tap((value) => {
          this.inFlightObservables.set(key, new Subject<T>());
          this.set(key, value, maxAge);
        }),
        catchError(error => {
          if (fallbackValue) {
            this.set(key, fallbackValue, 0);
            return of(fallbackValue);
          }
          this.notifyInFlightObserversError(key, error);
          throw error;
        }),
        startWith(this.cache.get(key)!.value)
      );
    } else {
      return this.get(key, fallback, maxAge, fallbackValue);
    }
  }

  /**
   * Sets the value with key in the cache
   * Notifies all observers of the new value
   */
  set<T>(key: string, value: T, maxAge: number = this.DEFAULT_MAX_AGE): void {
    this.cache.set(key, { value, expiry: Date.now() + maxAge  });
    this.notifyInFlightObservers(key, value);
  }

  /**
   * Checks if the a key exists in cache
   */
  has(key: string): boolean {
    return this.cache.has(key);
  }

  /**
   * Publishes the value to all observers of the given
   * in progress observables if observers exist.
   */
  private notifyInFlightObservers(key: string, value: any): void {
    if (this.inFlightObservables.has(key)) {
      const inFlight = this.inFlightObservables.get(key)!;
      const observersCount = inFlight.observers.length;
      if (observersCount) {
        inFlight.next(value);
      }
      inFlight.complete();
      this.inFlightObservables.delete(key);
    }
  }

  private notifyInFlightObserversError(key: string, value: any): void {
    if (this.inFlightObservables.has(key)) {
      const inFlight = this.inFlightObservables.get(key)!;
      const observersCount = inFlight.observers.length;
      if (observersCount) {
        inFlight.error(value);
      }
      this.inFlightObservables.delete(key);
    }
  }

  /**
   * Clear the cache for the given key
   */
  clear(key: string) {
    this.cache.delete(key);
  }

  /**
   * Clear the cache
   */
  clearAll() {
    this.cache = new Map<string, CacheContent>();
    this.inFlightObservables = new Map<string, Subject<any>>();
  }

  /**
   * Checks if the key exists and   has not expired.
   */
  private hasValidCachedValue(key: string): boolean {
    if (this.cache.has(key)) {
      if (this.cache.get(key)!.expiry < Date.now()) {
        this.cache.delete(key);
        return false;
      }
      return true;
    } else {
      return false;
    }
  }
}
