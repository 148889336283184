<h2 matDialogTitle class="mb-0" style="font-weight: 500;">Qual grupo você quer entrar?</h2>

<mat-dialog-content class="p-1">
  <div class="cards">
    <ng-container *ngIf="!groups">
      <div class="card" (click)="showGeneral()">
        <app-rb-icon class="text-primary" icon="shopping_basket"></app-rb-icon>
        <small class="mt-05">Promoções gerais</small>
      </div>
      
      <div class="card ml-1" (click)="showHardware()">
        <app-rb-icon class="text-primary" icon="computer"></app-rb-icon>
        <small class="mt-05">Promoções de hardware</small>
      </div>
    </ng-container>
    
    <ng-container *ngIf="groups">
      <a class="card" [href]="groups.whatsapp">
        <app-rb-icon class="text-primary" icon="whatsapp"></app-rb-icon>
        <small class="mt-1">WhatsApp</small>
      </a>
      
      <a class="card ml-1" [href]="groups.telegram">
        <app-rb-icon class="text-primary" icon="telegram"></app-rb-icon>
        <small class="mt-1">Telegram</small>
      </a>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="pl-1 pr-1 pb-1">
  <button class="full-width" mat-stroked-button (click)="back()">Voltar</button>
</mat-dialog-actions>